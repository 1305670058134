import React from 'react';
import fishVideo from "../video/fish-desktop.mp4";
import watermelonVideo from "../video/watermelon-desktop.mp4";
import steakVideo from "../video/steak-desktop.mp4";
import tomatoVideo from "../video/tomato-desktop.mp4";
import fishVideoMobile from "../video/fish-mobile.mp4";
import watermelonVideoMobile from "../video/watermelon-mobile.mp4";
import steakVideoMobile from "../video/steak-mobile.mp4";
import tomatoVideoMobile from "../video/tomato-mobile.mp4";
import ReactHowler from 'react-howler';
import { TweenMax, Expo } from 'gsap';
import { canPlay } from '../components/sound-controller';

export default class Fruits extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            slugFruit: fishVideo,
            fruits: [
                {url:"fish", slug: fishVideo, slugMobile: fishVideoMobile ,active: true}, 
                {url:"watermelon", slug: watermelonVideo, slugMobile: watermelonVideoMobile, active: false}, 
                {url:"steak", slug: steakVideo, slugMobile: steakVideoMobile, active: false},
                {url:"tomato", slug: tomatoVideo, slugMobile: tomatoVideoMobile, active: false}
            ],
            active: 'fish'
        }

        this.activeFruitDesktop = fishVideo;
        this.activeFruitMobile = fishVideoMobile;
        this.activeSound= 'fish';
        this.clickFruits = this.clickFruits.bind(this);
    }

    componentDidMount(){
        var playPromiseFresh = document.querySelector(".fresh-video video").play();
        if (playPromiseFresh !== undefined) {
          playPromiseFresh.then(function() {        
          }).catch(function(error) {
          });
        }

        TweenMax.from('.fruits-list-container', 0.7, {
            x: 250,
            delay: 0.2, 
            ease: Expo.easeOut
        });

        if(!canPlay){
            window.Howler.mute(true);
        }
    }

    clickFruits = (i) => {
        const that = this;
        this.setState(prevState => ({
            fruits: prevState.fruits.map(
                (elem, key) => (key === i ? Object.assign(elem, { active: true }) : Object.assign(elem, { active: false }))
            )
        }));

        this.setState(prevState => ({
            fruits: prevState.fruits.map(function(elem, key){
                if(key === i){
                    that.activeFruitDesktop = elem.slug;
                    that.activeFruitMobile = elem.slugMobile;
                    that.activeSound = elem.url;
                    return Object.assign(elem, { active: true })
                } else {
                    return Object.assign(elem, { active: false })
                }
            })
        }));

    }

    componentWillUnmount(){
        this.activeFruitDesktop = fishVideo;
        this.activeFruitMobile = fishVideoMobile;
        this.activeSound= 'fish';
    }

    render(){
        if(typeof window !== 'undefined'){
            if(window.innerWidth > 768){
                return(
                    <React.Fragment>
                        <ReactHowler
                            src={`../${this.activeSound}.mp3`}
                            playing={true}
                            loop={false}
                            volume={0.3}
                        />
                        <section className="fresh-video">
                            <div className="block-bg-cover"> 
                                <video className="element-cover" muted autoPlay playsInline key={this.activeFruitDesktop}>
                                    <source src={this.activeFruitDesktop} type="video/mp4"/>
                                </video>
                            </div>
                        </section>
                        <ul className="fruits-list-container">
                            {this.state.fruits.map((elem, i) => (
                                <li key={i} onClick={this.clickFruits.bind(Fruits, i)} className={elem.active ? "active" : null}>
                                <img src={`../${elem.url}.png`} alt="Letter Thumbnail" />
                                </li>
                            ))}
                        </ul>
                    </React.Fragment>
                )
            } else {
                return(
                    <React.Fragment>
                        <ReactHowler
                            src={`../${this.activeSound}.mp3`}
                            playing={true}
                            loop={false}
                            volume={0.3}
                        />
                        <section className="fresh-video">
                            <div className="block-bg-cover"> 
                                <video className="element-cover" muted autoPlay playsInline key={this.activeFruitMobile}>
                                    <source src={this.activeFruitMobile} type="video/mp4"/>
                                </video>
                            </div>
                        </section>
                        <ul className="fruits-list-container">
                            {this.state.fruits.map((elem, i) => (
                                <li key={i} onClick={this.clickFruits.bind(Fruits, i)} className={elem.active ? "active" : null}>
                                <img src={`../${elem.url}.png`} alt="Letter Thumbnail" />
                                </li>
                            ))}
                        </ul>
                    </React.Fragment>
                )
            }
        } else {
            return(
                null
            )
        }
    }
}