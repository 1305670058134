import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubMenu from "../components/sub-menu"
import ScrollHelper from "../components/scrollHelper"
import BrandLogo from "../components/brand-logo"
import ZiqqiFreshSecondaryRed from "../images/ziqqi-fresh-secondary-red.svg"
import ZiqqiFreshSecondaryWhite from "../images/ziqqi-fresh-secondary-white.svg"
import BrandIntro from "../components/brand-intro"
import Fullscreen from "../components/fullscreen"
import Button from "../components/button"
import ColorPalette from "../components/color-palette"
import PreFooter from "../components/pre-footer"
import Fruits from "../components/fruits"
import Footer from "../components/footer"

const BrandLogoContainer = () => {
  return(
    <React.Fragment>
      <section data-target data-value="Logo">
        <article className="brand-logo">
          <div className="row expanded align-center align-middle">
            <div className="xxlarge-16 columns">
              <div className="row expanded xxlarge-up-2 xsmall-up-1">
                <div className="columns nopadd">
                  <BrandLogo title="Main Version — Positive" pathSvg={<ZiqqiFreshSecondaryRed/>} background="#f6f6f6" color="#000000" classButton="red-version" mainClass="small-brand"/>
                </div>
                <div className="columns nopadd">
                  <BrandLogo title="Main Version — Negative" pathSvg={<ZiqqiFreshSecondaryWhite/>} background="#FF523A" color="#ffffff" classButton="red-white-version" mainClass="small-brand"/>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
    </React.Fragment>
  )
}

const subMenuList = ["Introduction", "Logo", "Colors"];

const subBrands = [
  {
    url: '/',
    imageURL: '../full-screen-brand-image.jpg',
    title: 'ZIQQI',
    text: 'Brings Africa the reliable one-stop online shopping experience, offering the widest variety of products.'
  },
  {
    url: '/ziqqi-mart/',
    imageURL: '../ziqqi-mart-fullscreen-02.jpg',
    title: 'ZIQQI Mart',
    text: 'Allows physical or virtual stores to affiliate with and get a commission on every sale.'
  }
]


const palettesGroup = [
  {
    palette: [
      {
        class: 'main',
        color: '',
        background: '#FF523A',
        colorElements: '#ffffff',
        properties: [
          {
            rgb: '255 / 83 / 57'
          },
          {
            cmyk: '0 / 82 / 81 / 0',
          }
        ]
      },
      {
        color: '',
        background: '#1F684B',
        colorElements: '#ffffff',
        properties: [
          {
            rgb: '30 / 104 / 75',
          },
          {
            cmyk: '86 / 36 / 78 / 26'
          }
        ]
      }
    ]
  },
  {
    palette: [
      {
        class: 'secondary',
        color: '',
        background: '#F2655A',
        colorElements: '#ffffff',
        properties: [
          {
            rgb: '255 / 122 / 122',
          },
          {
            cmyk: '0 / 66 / 42 / 0'
          }
        ]
      },
      {
        color: '',
        background: '#EFAEBA',
        colorElements: '#000000',
        properties: [
          {
            rgb: '250 / 211 / 208',
          },
          {
            cmyk: '0 / 24 / 14 / 0'
          }
        ]
      },
      {
        color: '',
        background: '#F2B61C',
        colorElements: '#000000',
        properties: [
          {
            rgb: '251 / 192 / 22',
          },
          {
            cmyk: '1 / 27 / 91 / 0'
          }
        ]
      },
      {
        color: '',
        background: '#7FBA6C',
        colorElements: '#000000',
        properties: [
          {
            rgb: '128 / 185 / 108',
          },
          {
            cmyk: '56 / 4 / 71 / 0'
          }
        ]
      },
      {
        color: '',
        background: '#E7E96A',
        colorElements: '#000000',
        properties: [
          {
            rgb: '238 / 255 / 104',
          },
          {
            cmyk: '15 / 0 / 72 / 0'
          }
        ]
      },
      {
        color: '',
        background: '#DCD5CD',
        colorElements: '#000000',
        properties: [
          {
            rgb: '220 / 214 / 205',
          },
          {
            cmyk: '16 / 14 / 20 / 0'
          }
        ]
      }
    ]
  }
]

const FreshHeader = () => {
  return(
    <section className="fresh-header">
      <ScrollHelper />
      {/* <FreshVideo/> */}
      <Fruits/>
      <Button name="Download the kit" caption="Includes all the variations of the logo." href="/" class="red-white-version ziqqi-button"></Button>
    </section>
  )
}

const ZiqqiFresh = () => (
  <Layout>
    <SEO title="ZIQQI Fresh" keywords={[`ziqqi mart`, `application`, `react`]} />
    <SubMenu list={subMenuList} backgroundColor="red-background"/>
    <FreshHeader />
    <BrandIntro class="fresh-intro intro" subtitle="ZIQQI Fresh supplies locally sourced, all organic, fruits and vegetables, meats, fish and dairy products." text="Connecting local farmers and fishermen with the most efficient distribution channels while supporting local communities with quality produce. Farm-to-Table; African-Style." url="../ziqqi-fresh-fullscreen-02" alt="ZIQQI Fresh" value="Introduction"/>
    <BrandLogoContainer />
    <ColorPalette palettes={palettesGroup} value="Colors"/>
    <Fullscreen url="../ziqqi-fresh-fullscreen-01" />
    <PreFooter subBrands={subBrands}/>
    <Footer />
  </Layout>
)

export default ZiqqiFresh

